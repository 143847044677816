import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import news from './modules/news';
import competition from './modules/competition';
import bookings from './modules/bookings';
import camo from './modules/camo';
import statistics from './modules/statistics';
import tankstelleUpdater from './modules/tankstelleUpdater';
import vouchers from './modules/vouchers';
import corona from './modules/corona';
import fluglehrerMotorflug from './modules/fluglehrerMotorflug';
import fluglehrerSegelflug from './modules/fluglehrerSegelflug';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules: {
        user,
        news,
        competition,
        bookings,
        camo,
        statistics,
        tankstelleUpdater,
        fluglehrerMotorflug,
        fluglehrerSegelflug,
        vouchers,
        corona
    }
});
