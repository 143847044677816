
import Vue from 'vue';
import Component from 'vue-class-component';
import { QrcodeStream } from 'vue-qrcode-reader';
import moment from 'moment';
import { Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    components: {
        QrcodeStream
    },
    computed: {
        ...mapGetters({
            code: 'vouchers/code',
            voucher: 'vouchers/voucher',
            info: 'vouchers/info',
            progress: 'vouchers/progress'
        })
    }
})
export default class VoucherPage extends Vue {
  public redeemVoucher: boolean = false;
  public issueVoucher: boolean = false;
  public eVoucher: boolean = false;
  public type: string = 'Motorflug';
  public voucherType: string = 'Normal';
  public adults: number = 1;
  public children: number = 0;
  public minutes: number = 15;
  public amount: string = '';
  public plane: string = 'D-';
  public pilot: string = '';
  public email: string = '';
  public error: string = '';
  public modal: boolean = false;

  public nowDate: any = new Date().toISOString().slice(0, 10);
  public date: any = null;
  public dateFormatted: any = null;

  public onDecode (code: any): any {
      this.$store.dispatch('vouchers/checkCode', { code: code });
  }

  public abort (): any {
      this.redeemVoucher = false;
      this.issueVoucher = false;
      this.eVoucher = false;
      this.type = 'Motorflug';
      this.voucherType = 'Normal';
      this.adults = 1;
      this.children = 0;
      this.minutes = 15;
      this.email = '';
      this.amount = '';
      this.plane = 'D-';
      this.pilot = '';
      this.calculateMotorflug();

      this.$store.dispatch('vouchers/abort');
  }

  /**
     * Prüft ob alle Vorgaben zum Einlösen eines Gutscheins erfüllt sind
     */
  public redeemVoucherDisabled (): boolean {
      return (this.pilot === '' || this.plane.length < 6 || this.date === null);
  }

  /**
   * Prüft ob alle Vorgaben zum Aktivieren eines Gutscheins erfüllt sind
   */
  public issueVoucherDisabled (): boolean {
      return (this.type === '' || this.voucherType === '' || this.amount === '');
  }

  /**
   * Prüft ob alle Vorgaben zum Versenden eines Gutscheins erfüllt sind
   */
  public eVoucherDisabled (): boolean {
      return (this.type === '' || this.voucherType === '' || this.amount === '' || (this.adults + this.children) > 3 || !this.validEmail(this.email));
  }

  /**
   * Ist die E-Mail gültig
   */
  public validEmail (email: string): boolean {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(email);
  }

  /**
   * Gutschein einlösen
   */
  public redeem (): any {
      this.$store.dispatch('vouchers/redeemVoucher', {
          pilot: this.pilot,
          plane: this.plane,
          date: this.date
      });
  }

  /**
   * Gutschein ausstellen
   */
  public issue (): any {
      this.$store.dispatch('vouchers/issueVoucher', {
          type: this.type,
          voucherType: this.voucherType,
          adults: this.adults,
          children: this.children,
          minutes: this.minutes,
          amount: this.amount
      });
  }

  /**
   * eVoucher im System anlegen und versenden
   */
  public send (): any {
      this.$store.dispatch('vouchers/eVoucher', {
          type: this.type,
          voucherType: this.voucherType,
          adults: this.adults,
          children: this.children,
          minutes: this.minutes,
          amount: this.amount,
          email: this.email
      });
  }

  @Watch('date')
  public formatDate (): void {
      this.dateFormatted = moment(this.date).format('DD.MM.YYYY');
  }

  @Watch('adults')
  public onAdultsChanged (): void {
      this.calculateMotorflug();
  }

  @Watch('children')
  public onChildrenChanged (): void {
      this.calculateMotorflug();
  }

  @Watch('minutes')
  public onMinutesChanged (): void {
      this.calculateMotorflug();
  }

  @Watch('type')
  public onTypeChanged (): void {
      if (this.type === 'Segelflug') {
          this.amount = '55.00';
      }

      if (this.type === 'Segelkunstflug') {
          this.amount = '100.00';
      }

      this.calculateMotorflug();
  }

  private calculateMotorflug (): void {
      if (this.type === 'Motorflug') {
          const adultAmount = (Math.floor(this.minutes / 15) * 35) + ((this.minutes % 15) * 2.5);
          const childrenAmount = (Math.floor(this.minutes / 15) * 30) + ((this.minutes % 15) * 2.5);

          const adultsCount = (this.adults !== undefined) ? this.adults : 0;
          const childrenCount = (this.children !== undefined) ? this.children : 0;

          let amount = (adultsCount * adultAmount) + (childrenCount * childrenAmount);

          // Eine Stunde wird auf 300€ gedeckelt
          if (this.minutes === 60 && amount > 300) {
              amount = 300;
          }

          this.amount = amount.toFixed(2);
      }
  }

  public formatTimeStamp (timestamp: any): string {
      return moment.unix(timestamp.seconds).format('DD.MM.YYYY');
  }

  public mounted (): void {
      this.calculateMotorflug();
  }
}
