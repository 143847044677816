export class Base64 {
    public static b64DecodeUnicode (str: string): string {
        if (window && 'atob' in window && 'decodeURIComponent' in window) {
            return decodeURIComponent(
                Array.prototype.map
                    .call(atob(str), (c: any) => {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );
        } else {
            console.warn(
                'b64DecodeUnicode requirements: window.atob and window.decodeURIComponent functions'
            );
            return '';
        }
    }
}
