
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import CookieLaw from 'vue-cookie-law';

@Component({
    components: {
        CookieLaw
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            hasRole: 'user/hasRole'
        })
    },
    methods: {
        ...mapActions({
            logout: 'user/logout'
        })
    }
})
export default class App extends Vue {
  private sideNav: boolean = false;
  private hasQueryParam: boolean = false;

  @Watch('$route')
  private onRouteChange (): void {
      this.setQueryParam();
  }

  public pages: Array<{
    icon: string;
    title: string;
    link: string;
    role: string;
    external: boolean;
  }> = [
      {
          icon: 'grade',
          title: 'News',
          link: '/',
          role: '',
          external: false
      },
      {
          icon: 'chrome_reader_mode',
          title: 'InfoDesk',
          link: '/infodesk',
          role: 'Mitglied AKTIV',
          external: false
      },
      {
          icon: 'radar',
          title: 'Flightradar',
          link: 'http://traffic.lsc-duempel.de/map.html',
          role: 'Mitglied',
          external: true
      },
      {
          icon: 'airplanemode_active',
          title: 'Flugzeuge',
          link: '/planes',
          role: 'Mitglied AKTIV',
          external: false
      },
      {
          icon: 'gesture',
          title: 'Streckenflug',
          link: '/haulflight',
          role: 'Mitglied',
          external: false
      },
      {
          icon: 'bar_chart',
          title: 'Statistik',
          link: '/statistics',
          role: 'Mitglied AKTIV',
          external: false
      },
      {
          icon: 'wb_sunny',
          title: 'Wetter',
          link: '/weather',
          role: 'Mitglied',
          external: false
      },
      // {
      //     icon: 'school',
      //     title: 'Fluglehrer Motorflug',
      //     link: '/fluglehrer_motorflug',
      //     role: 'app_fi_motor',
      //     external: false
      // },
      {
          icon: 'school',
          title: 'Fluglehrer Segelflug',
          link: '/fluglehrer_segelflug',
          role: 'app_fi_segel',
          external: false
      },
      {
          icon: 'local_gas_station',
          title: 'Tankstelle',
          link: '/gasstation',
          role: 'app_tankstelle',
          external: false
      },
      {
          icon: 'card_giftcard',
          title: 'Gutscheine',
          link: '/vouchers',
          role: 'app_voucher',
          external: false
      },
      {
          icon: 'shopping_cart',
          title: 'Kioskguthaben',
          link: '/kiosk',
          role: 'Mitglied',
          external: false
      },
      {
          icon: 'smartphone',
          title: 'Vereinsflieger',
          link: 'https://www.vereinsflieger.de',
          role: '',
          external: true
      },
      {
          icon: 'navigation',
          title: 'VFR AIP',
          link: 'https://www.vfraip.de',
          role: '',
          external: true
      },
      {
          icon: 'phone',
          title: 'Notfall',
          link: '/emergency',
          role: '',
          external: false
      }
  ];

  private goBack () {
      this.$router.go(-1);
  }

  private setQueryParam () {
      this.hasQueryParam = Object.keys(this.$route.query).length > 0;
  }

  private created () {
      this.setQueryParam();
  }
}
