import * as firebase from 'firebase';

const state: any = {
    students: []
};

const mutations = {
    pilots: (state: any, payload: any) => {
        state.pilots = payload;
    }
};

const actions = {
    loadData: ({ commit }: any) => {
        firebase
            .firestore()
            .collection('fi_motor')
            .orderBy('name', 'asc')
            .onSnapshot((querySnapshot: any) => {
                const pilots: any = [];
                querySnapshot.forEach((doc: any) => {
                    pilots.push({ id: doc.id, data: doc.data() });
                });

                commit('pilots', pilots);
            });
    }
};

const getters = {
    pilots: (state: any) => {
        return state.pilots;
    }
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};
