var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"reservationPage"},[_c('v-container',{staticStyle:{"min-height":"0"},attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","lg4":""}},[_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v("CAMO")]),_c('div',[_vm._v("Letzte Aktualisierung: "+_vm._s(_vm.formatDate(_vm.camo.crdate)))])]),(_vm.camoUpToDate)?_c('div',[_vm._l((_vm.camo.data),function(plane){return _c('div',{key:plane.id,staticClass:"camo",class:{
                bgWarning: plane.lta || plane.interneanweisung,
                bgDanger: plane.startverbot || plane.meldefrist
              }},[_vm._v(" "+_vm._s(plane.kennzeichen)+" "+_vm._s(plane.typ)+" "),_c('div',{staticClass:"icons"},[(plane.startverbot)?_c('img',{attrs:{"src":require('@/assets/img/infoDesk/stop_mini.png')}}):_vm._e(),(plane.lta)?_c('img',{attrs:{"src":require('@/assets/img/infoDesk/achtung_mini.png')}}):_vm._e(),(plane.interneanweisung)?_c('img',{attrs:{"src":require('@/assets/img/infoDesk/flugbetrieb_mini.png')}}):_vm._e(),(plane.meldefrist)?_c('img',{attrs:{"src":require('@/assets/img/infoDesk/einbahnstrasse_mini.png')}}):_vm._e()])])}),_c('div',{staticClass:"legend"},[_c('div',[_c('img',{attrs:{"src":require('@/assets/img/infoDesk/stop_mini.png')}}),_vm._v(" Startverbot ")]),_c('div',[_c('img',{attrs:{"src":require('@/assets/img/infoDesk/einbahnstrasse_mini.png')}}),_vm._v(" Meldefrist überschritten ")]),_c('div',[_c('img',{attrs:{"src":require('@/assets/img/infoDesk/achtung_mini.png')}}),_vm._v(" aktuelle LTA/AD ")]),_c('div',[_c('img',{attrs:{"src":require('@/assets/img/infoDesk/flugbetrieb_mini.png')}}),_vm._v(" interne Anweisung ")])])],2):_c('div',[_c('v-alert',{attrs:{"value":true,"type":"error"}},[_vm._v("Die Daten sind älter als 12 Stunden und werden deswegen nicht mehr angezeigt.")])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","lg8":""}},[_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v("Reservierungen und Dienste")]),_c('div',[_vm._v(" Letzte Aktualisierung: "+_vm._s(_vm.formatDate(_vm.bookings.crdate))+" ")])])],1),(_vm.bookingsUpToDate)?_c('div',_vm._l((_vm.bookings.data),function(booking){return _c('v-card',{key:booking.id,staticClass:"mt-2 booking infoDeskBg",class:{
              bgDanger: booking.sperrung,
              bgDienst1:
                booking.spalte1 === 'Flugleiter' ||
                booking.spalte1 === 'Segelfluglehrer',
              bgDienst2:
                booking.spalte1 === 'Schlepp- / Gastflug' ||
                booking.spalte1 === 'Kioskdienst'
            }},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"topic smallPadding",attrs:{"xs12":"","lg3":""}},[_vm._v(_vm._s(booking.spalte1))]),_c('v-flex',{staticClass:"smallPadding",attrs:{"xs12":"","lg3":""}},[_vm._v(_vm._s(booking.spalte2))]),_c('v-flex',{staticClass:"smallPadding",attrs:{"xs12":"","lg3":""}},[_vm._v(_vm._s(booking.spalte3))]),_c('v-flex',{staticClass:"smallPadding",attrs:{"xs12":"","lg3":""}},[_vm._v(_vm._s(booking.spalte4))])],1)],1)}),1):_c('div',[_c('v-alert',{attrs:{"value":true,"type":"error"}},[_vm._v("Die Daten sind älter als 20 Minuten und werden deswegen nicht mehr angezeigt.")])],1)],1)],1),(_vm.spinner)?_c('div',{staticClass:"spinner"},[_c('div',{staticClass:"innerSpinner"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":70,"width":7,"color":"blue"}})],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }