
import Axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import Card from '@/components/card/Card.vue';
import { mapGetters } from 'vuex';
import store from '../../store';
import * as moment from 'moment';
@Component({
    components: {
        Card
    },
    computed: {
        ...mapGetters({
            userkey: 'user/userkey',
            hasRole: 'user/hasRole'
        })
    }
})
export default class KioskPage extends Vue {
  private spinner: boolean = false;
  private credit: any = false;
  private revenues: any = false;
  private revenuesLastMonth: any = false;
  private revenuesUser: any = false;
  private revenuesLastMonthUser: any = false;
  private dailyTurnovers: any = false;
  private userList: any = false;
  private showError: boolean = false;
  private error: any = '';

  private loadCredit () {
      const self = this;

      // Axios.get('http://lsckiosk.development/api/api.php?dailyTurnover=' + store.getters['user/hasRole']('app_kioskauswertung') + '&token=' + store.getters['user/userkey'])
      Axios.get(
          'https://2x91d5.lsc-duempel.de/api/api.php?dailyTurnover=' +
        store.getters['user/hasRole']('app_kioskauswertung') +
        '&token=' +
        store.getters['user/userkey']
      )
          .then(response => {
              self.credit = response.data.credit;
              self.revenues = response.data.revenues;
              self.revenuesLastMonth = response.data.revenuesLastMonth;
              self.dailyTurnovers = response.data.dailyTurnovers;
              self.userList = response.data.userList;
              self.spinner = false;
              self.showError = false;
              if (self.credit === false) {
                  self.showError = true;
                  self.error = 'Function returned false';
              }
          })
          .catch(error => {
              self.error = error;
              self.showError = true;
              self.spinner = false;
          });
  }

  formatDate (timestamp: any): string {
      return moment.unix(timestamp).format('DD.MM.YYYY');
  }

  showUser (serial: any): void {
      const self = this;

      // Axios.get('http://lsckiosk.development/api/api.php?showUser=' + store.getters['user/hasRole']('app_kioskauswertung') + '&serial=' + serial)
      Axios.get(
          'https://2x91d5.lsc-duempel.de/api/api.php?showUser=' +
        store.getters['user/hasRole']('app_kioskauswertung') +
        '&serial=' +
        serial
      )
          .then(response => {
              self.revenuesUser = response.data.revenuesUser;
              self.revenuesLastMonthUser = response.data.revenuesLastMonthUser;
              self.spinner = false;
              self.showError = false;
              if (self.credit === false) {
                  self.showError = true;
                  self.error = 'Function returned false';
              }
          })
          .catch(error => {
              self.error = error;
              self.showError = true;
              self.spinner = false;
          });
  }

  private created () {
      this.spinner = true;
      this.$store.dispatch('user/autoLogin').then(() => {
          this.loadCredit();
      });
  }
}
