var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[(_vm.flightSchool.data.length > 0)?_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v("Schulung 2024 - TOP 10")])]),_c('v-card-text',[_c('table',_vm._l((_vm.flightSchool.data),function(flight){return _c('tr',{key:flight.pilot},[_c('td',{domProps:{"innerHTML":_vm._s(flight.pilot + ' (' + flight.starts + ')')}}),_c('td',[_c('div',{staticClass:"bar",style:('width:' + (100 / _vm.flightSchoolMax) * flight.starts + '%')})])])}),0)])],1):_vm._e(),_c('br'),(_vm.towing.data.length > 0)?_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v("Schlepps 2024 - TOP 10")])]),_c('v-card-text',[_c('table',_vm._l((_vm.towing.data),function(schlepp){return _c('tr',{key:schlepp.pilot},[_c('td',{domProps:{"innerHTML":_vm._s(schlepp.pilot + ' (' + schlepp.towings + ')')}}),_c('td',[_c('div',{staticClass:"bar",style:('width:' + (100 / _vm.towingMax) * schlepp.towings + '%')})])])}),0)])],1):_vm._e(),_c('br'),(_vm.planes.data.length > 0)?_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v("Flugzeiten 2024")])]),_c('v-card-text',[_c('table',_vm._l((_vm.planes.data),function(plane){return _c('tr',{key:plane.callsign},[_c('td',{domProps:{"innerHTML":_vm._s(
                  plane.callsign +
                    ' - ' +
                    plane.time +
                    ' h / ' +
                    plane.landings +
                    ' Land.'
                )}}),_c('td',[_c('div',{staticClass:"bar",style:('width:' + (100 / _vm.planesMax) * plane.timeInt + '%')})])])}),0)])],1):_vm._e(),_c('br'),(_vm.guestFlights.data.length > 0)?_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v("Gastflüge 2024")])]),_c('v-card-text',[_c('table',_vm._l((_vm.guestFlights.data),function(guestFlight){return _c('tr',{key:guestFlight.pilot},[_c('td',{domProps:{"innerHTML":_vm._s(guestFlight.pilot + ' (' + guestFlight.time + ' h)')}}),_c('td',[_c('div',{staticClass:"bar",style:('width:' +
                      (100 / _vm.guestFlightsMax) * guestFlight.timeInt +
                      '%')})])])}),0)])],1):_vm._e(),_c('br'),(_vm.guestFlightsGlider.data.length > 0)?_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v("Segelgastflüge 2024")])]),_c('v-card-text',[_c('table',_vm._l((_vm.guestFlightsGlider.data),function(guestFlightGlider){return _c('tr',{key:guestFlightGlider.pilot},[_c('td',{domProps:{"innerHTML":_vm._s(
                  guestFlightGlider.pilot +
                    ' (' +
                    guestFlightGlider.time +
                    ' h)'
                )}}),_c('td',[_c('div',{staticClass:"bar",style:('width:' +
                      (100 / _vm.guestFlightsGliderMax) *
                        guestFlightGlider.timeInt +
                      '%')})])])}),0)])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }