
import Vue from 'vue';
import Component from 'vue-class-component';
import Card from '@/components/card/Card.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import store from '../../store';

@Component({
    components: {
        Card
    },
    computed: {
        ...mapGetters({
            bookings: 'bookings/bookings',
            camo: 'camo/camo'
        })
    }
})
export default class ReservationPage extends Vue {
  private spinner: boolean = false;
  private camoUpToDate: boolean = false;
  private bookingsUpToDate: boolean = false;

  private formatDate (date: string): string {
      return moment(Number(date) * 1000).format('DD.MM.YYYY HH:mm');
  }

  private checkUpToDate () {
      this.camoUpToDate =
      moment().unix() - Number(store.getters['camo/camo'].crdate) <
      60 * 60 * 12;
      this.bookingsUpToDate =
      moment().unix() - Number(store.getters['bookings/bookings'].crdate) <
      60 * 20;
  }

  private created () {
      this.checkUpToDate();
      setInterval(this.checkUpToDate, 60000); // Jede Minute prüfen
  }
}
