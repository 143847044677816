
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import store from '@/store';
import * as moment from 'moment';
import { mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters({
            hasRole: 'user/hasRole'
        })
    }
})
export default class FluglehrerSegelflugPage extends Vue {
  @Getter('fluglehrerSegelflug/students') students: any;
  @Getter('user/username') username: string;
  @Getter('user/isSegelfluglehrer') isSegelfluglehrer: boolean;
  @Getter('user/isSegelfluglehrerAdmin') isSegelfluglehrerAdmin: boolean;
  private studentsFiltered: any = [];
  private studentSelected: any = false;
  private entrySelected: any = false;
  private entrySelectedId: any = null;

  private searchText: string = '';

  private showDeleteStudentDialog: boolean = false;
  private showDeleteEntryDialog: boolean = false;
  private showDeleteCommentDialog: boolean = false;

  private showAdd: boolean = false;
  private showEdit: boolean = false;
  private showAddEntry: boolean = false;
  private showEditEntry: boolean = false;
  private showAddComment: boolean = false;
  private name: string = '';
  private milestone: string = '';
  private entryTopic: string = '';
  private entryContent: string = '';
  private commentContent: string = '';
  private commentToBeRemoved: string = '';

  @Watch('students', { deep: true })
  private onUpdateStudents (): void {
      console.log('Student update detected');
      this.studentsFiltered = this.students;
      for (const singleStudent of this.studentsFiltered) {
          if (singleStudent.id === this.$route.query.student) {
              this.studentSelected = singleStudent;
          }
      }
  }

  @Watch('searchText')
  private onSearchTextChange (): void {
      const tmpStudents: any = [];
      const regex = new RegExp('.*' + this.searchText + '.*', 'i');

      for (const student of store.getters['fluglehrerSegelflug/students']) {
          if (
              this.searchText === null ||
        this.searchText === '' ||
        student.data.name.match(regex) !== null
          ) {
              tmpStudents.push(student);
          }
      }

      this.studentsFiltered = tmpStudents;
  }

  private createStudent () {
      this.$store
          .dispatch('fluglehrerSegelflug/createStudent', {
              name: this.name,
              user: this.username
          })
          .then(() => {
              this.showAdd = false;
              this.name = '';
          });
  }

  private editStudent () {
      this.$store
          .dispatch('fluglehrerSegelflug/editStudent', {
              student: this.studentSelected,
              name: this.name,
              milestone: this.milestone,
              user: this.username
          })
          .then(() => {
              this.showEdit = false;
          });
  }

  // Zeigt den Löschen Dialog an
  private removeStudentDialog () {
      this.showDeleteStudentDialog = true;
  }

  private removeStudent () {
      this.$store
          .dispatch('fluglehrerSegelflug/removeStudent', {
              student: this.studentSelected,
              user: this.username
          })
          .then(() => {
              this.showDeleteStudentDialog = false;
              this.$router.go(-1);
          });
  }

  private createEntry () {
      this.$store
          .dispatch('fluglehrerSegelflug/createEntry', {
              student: this.studentSelected,
              topic: this.entryTopic,
              content: this.entryContent,
              user: this.username
          })
          .then(() => {
              this.showAddEntry = false;
              this.entryTopic = '';
              this.entryContent = '';
          });
  }

  private editEntry () {
      this.$store
          .dispatch('fluglehrerSegelflug/editEntry', {
              student: this.studentSelected,
              entryId: this.entrySelectedId,
              topic: this.entryTopic,
              content: this.entryContent,
              user: this.username
          })
          .then(() => {
              this.showEditEntry = false;
          });
  }

  private removeEntry () {
      this.$store
          .dispatch('fluglehrerSegelflug/removeEntry', {
              student: this.studentSelected,
              entryId: this.entrySelectedId,
              user: this.username
          })
          .then(() => {
              this.showDeleteEntryDialog = false;
              this.$router.go(-1);
          });
  }

  private createComment () {
      this.$store
          .dispatch('fluglehrerSegelflug/createComment', {
              student: this.studentSelected,
              entryId: this.entrySelectedId,
              content: this.commentContent,
              user: this.username
          })
          .then(() => {
              this.showAddComment = false;
              this.commentContent = '';
          });
  }

  private openRemoveCommentDialog (id: any) {
      this.showDeleteCommentDialog = true;
      this.commentToBeRemoved = id;
  }

  private removeComment () {
      this.$store
          .dispatch('fluglehrerSegelflug/removeComment', {
              student: this.studentSelected,
              entryId: this.entrySelectedId,
              commentId: this.commentToBeRemoved,
              user: this.username
          })
          .then(() => {
              this.showDeleteCommentDialog = false;
              this.commentToBeRemoved = '';
          });
  }

  private setDone (flag: boolean) {
      this.$store
          .dispatch('fluglehrerSegelflug/setDone', {
              student: this.studentSelected,
              entryId: this.entrySelectedId,
              flag: flag,
              user: this.username
          });
  }

  private selectEntry (id: any) {
      this.$router.push({ path: 'fluglehrer_segelflug', query: { student: this.$route.query.student, entry: id } });
  }

  private selectStudent (index: any) {
      this.$router.push({
          path: '/fluglehrer_segelflug',
          query: { student: index }
      });
  }

  // Wann wurde irgendwas am Schüler zuletzt geändert?
  private lastChangedStudent (student: any): string {
      let changed = student.data.changed;
      if (student.data.entries !== undefined) {
          for (const entry in student.data.entries) {
              if (student.data.entries[entry].changed !== undefined && student.data.entries[entry].changed.seconds > changed.seconds) {
                  changed = student.data.entries[entry].changed;
              }

              if (student.data.entries[entry].comments !== undefined) {
                  for (const comment in student.data.entries[entry].comments) {
                      if (student.data.entries[entry].comments[comment].changed.seconds > changed.seconds) {
                          changed = student.data.entries[entry].comments[comment].changed;
                      }
                  }
              }
          }
      }

      return this.formatDate(changed);
  }

  // Wann wurde irgendwas am Eintrag zuletzt geändert?
  private lastChangedEntry (entry: any): string {
      let changed = entry.changed;
      if (entry.comments !== undefined) {
          for (const comment in entry.comments) {
              if (entry.comments[comment].changed.seconds > changed.seconds) {
                  changed = entry.comments[comment].changed;
              }
          }
      }

      return this.formatDate(changed);
  }

  private reverseObjects (objects: any) {
      const arr : any = [];
      for (const entry in objects) {
          arr.push(entry);
      }
      return arr.reverse();
  }

  private sortByLastChange (student: any) {
      const tmpArr : any = [];

      if (student.data.entries !== undefined) {
          for (const entry in student.data.entries) {
              let changed = student.data.changed;
              if (student.data.entries[entry].changed !== undefined && student.data.entries[entry].changed.seconds > changed.seconds) {
                  changed = student.data.entries[entry].changed;
              }

              if (student.data.entries[entry].comments !== undefined) {
                  for (const comment in student.data.entries[entry].comments) {
                      if (student.data.entries[entry].comments[comment].changed.seconds > changed.seconds) {
                          changed = student.data.entries[entry].comments[comment].changed;
                      }
                  }
              }
              tmpArr[changed.seconds] = entry;
          }
      }

      const arr : any = [];
      for (const index in tmpArr) {
          arr.push(tmpArr[index]);
      }

      return arr.reverse();
  }

  formatDate (timestamp: any): string {
      return moment.unix(timestamp.seconds).format('DD.MM.YYYY');
  }

  private mounted () {
      // Sync lokal Variable to VUEX store
      this.studentsFiltered = this.students;

      if (this.$route.query.student !== undefined) {
          for (const singleStudent of this.studentsFiltered) {
              if (singleStudent.id === this.$route.query.student) {
                  this.studentSelected = singleStudent;
                  this.name = this.studentSelected.data.name;
                  this.milestone = this.studentSelected.data.milestone;
              }
          }

          if (this.$route.query.entry !== undefined) {
              this.entrySelectedId = this.$route.query.entry;
              this.entrySelected = this.studentSelected.data.entries[this.entrySelectedId];
              this.entryTopic = this.studentSelected.data.entries[this.entrySelectedId].topic;
              this.entryContent = this.studentSelected.data.entries[this.entrySelectedId].content;
          }
      }
  }
}
