import Vue from 'vue';
import * as firebase from 'firebase';
import Axios from 'axios';

const state: any = {
    code: '',
    voucher: {},
    info: {
        type: '',
        message: ''
    },
    progress: false
};

const mutations = {
    voucher: (state: any, payload: any) => {
        Vue.set(state, 'code', payload.code);
        Vue.set(state, 'voucher', payload.data);
    },
    abort: (state: any) => {
        Vue.set(state, 'code', '');
        Vue.set(state, 'voucher', {});
        Vue.set(state, 'info', { type: '', message: '' });
        Vue.set(state, 'progress', false);
    }
};

const actions = {
    /**
     * Prüft ob es einen Code in der Datenbank gibt
     * @param commit
     * @param payload
     */
    checkCode: ({ commit }: any, payload: any) => {
        state.progress = true;

        firebase
            .firestore()
            .collection('vouchers')
            .doc(payload.code)
            .get()
            .then((doc: any) => {
                if (doc.exists) {
                    commit('voucher', {
                        code: doc.id,
                        data: doc.data()
                    });
                    state.progress = false;
                } else {
                    commit('voucher', {
                        code: 'invalid',
                        data: {}
                    });
                    state.progress = false;
                }
            });
    },

    /**
     * Aktiviert einen Code
     * @param commit
     * @param payload
     */
    issueVoucher: ({ commit }: any, payload: any) => {
        state.progress = true;
        const currentUser = firebase.auth().currentUser;

        firebase
            .firestore()
            .collection('vouchers')
            .doc(state.code)
            .get()
            .then((doc: any) => {
                if (doc.exists && doc.data().status === 'new') {
                    firebase
                        .firestore()
                        .collection('vouchers')
                        .doc(state.code)
                        .update({
                            status: 'activated',
                            type: payload.type,
                            voucherType: payload.voucherType,
                            adults: payload.adults,
                            children: payload.children,
                            minutes: payload.minutes,
                            amount: payload.amount,
                            issueDate: firebase.firestore.FieldValue.serverTimestamp(),
                            issuer: currentUser.displayName,
                            issuerEmail: currentUser.email
                        }).then(() => {
                            state.progress = false;
                            Vue.set(state.info, 'type', 'success');
                            Vue.set(state.info, 'message', 'Gutschein wurde aktiviert');
                        // eslint-disable-next-line handle-callback-err
                        }).catch((error: any) => {
                            Vue.set(state.info, 'type', 'error');
                            Vue.set(state.info, 'message', 'Gutschein konnte nicht aktiviert werden');
                            state.progress = false;
                        });
                }
            });
    },

    /**
     * Löst einen Code ein
     * @param commit
     * @param payload
     */
    redeemVoucher: ({ commit }: any, payload: any) => {
        state.progress = true;
        const currentUser = firebase.auth().currentUser;

        firebase
            .firestore()
            .collection('vouchers')
            .doc(state.code)
            .get()
            .then((doc: any) => {
                if (doc.exists && doc.data().status === 'activated') {
                    firebase
                        .firestore()
                        .collection('vouchers')
                        .doc(state.code)
                        .update({
                            status: 'redeemed',
                            pilot: payload.pilot,
                            plane: payload.plane,
                            flightDate: payload.date,
                            redeemDate: firebase.firestore.FieldValue.serverTimestamp(),
                            redeemer: currentUser.displayName,
                            redeemerEmail: currentUser.email
                        }).then(() => {
                            state.progress = false;
                            Vue.set(state.info, 'type', 'success');
                            Vue.set(state.info, 'message', 'Gutschein wurde eingelöst');
                        // eslint-disable-next-line handle-callback-err
                        }).catch((error: any) => {
                            state.progress = false;
                            Vue.set(state.info, 'type', 'error');
                            Vue.set(state.info, 'message', 'Gutschein konnte nicht eingelöst werden');
                        });
                }
            });
    },

    /**
   * Erstellt einen Eintrag in der Datenbank, aus dem ein eVoucher erzeugt werden kann
   *
   * @param commit
   * @param payload
   */
    eVoucher: ({ commit }: any, payload: any) => {
        state.progress = true;
        const currentUser = firebase.auth().currentUser;

        firebase
            .firestore()
            .collection('eVouchers')
            .add({
                status: 'new',
                type: payload.type,
                voucherType: payload.voucherType,
                adults: payload.adults,
                children: payload.children,
                minutes: payload.minutes,
                amount: payload.amount,
                email: payload.email,
                crdate: firebase.firestore.FieldValue.serverTimestamp(),
                creator: currentUser.displayName,
                creatorEmail: currentUser.email
            })
            .then(function (docRef) {
                Vue.set(state.info, 'type', 'info');
                Vue.set(state.info, 'message', 'E-Mail wird gesendet');

                // TODO umstellen
                Axios.get('http://lscappbackend.development/api/voucher/' + docRef.id)
                // Axios.get('https://402d345c48.mpx-easystar.de/api/voucher/' + docRef.id)
                    .then(response => {
                        Vue.set(state.info, 'type', response.data.type);
                        Vue.set(state.info, 'message', response.data.message);
                        state.progress = false;
                    })
                    // eslint-disable-next-line handle-callback-err
                    .catch(error => {
                        Vue.set(state.info, 'type', 'error');
                        Vue.set(state.info, 'message', 'Fehler beim Versenden der E-Mail');
                        state.progress = false;
                    });
            })
            // eslint-disable-next-line handle-callback-err
            .catch(error => {
                Vue.set(state.info, 'type', 'error');
                Vue.set(state.info, 'message', 'Gutschein konnte nicht angelgt werden');
                state.progress = false;
            });
    },

    /**
     * Bricht einen Vorgang ab und setzt die Werte zurück
     * @param commit
     * @param payload
     */
    abort: ({ commit }: any, payload: any) => {
        commit('abort');
    }
};

const getters = {
    code: (state: any) => {
        return state.code;
    },
    voucher: (state: any) => {
        return state.voucher;
    },
    info: (state: any) => {
        return state.info;
    },
    progress: (state: any) => {
        return state.progress;
    }
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};
