const planes = [];

planes.push({
    registration: 'D-EJEK',
    type: 'Robin Aircraft DR400',
    image: 'planes/d-ejek.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/25bqhAFMM',
    cards: [
        {
            title: 'Geschwindigkeiten',
            type: 'table',
            data: [
                {
                    key: 'V<sub>R</sub> <span class="small">Abheben</span>',
                    value: '100 km/h'
                },
                {
                    key: 'V<sub>X</sub> <span class="small">bester Steigwinkel</span>',
                    value: '130 km/h - Klappen 1'
                },
                {
                    key: 'V<sub>Y</sub> <span class="small">beste Steigrate</span>',
                    value: '160 km/h'
                },
                {
                    key: 'V<sub>BG</sub> <span class="small">bestes Gleiten</span>',
                    description: '',
                    value: '1:9,3 bei 145 km/h'
                },
                {
                    key: 'V<sub>A</sub> <span class="small">voller Ruderausschlag</span>',
                    value: '215 km/h'
                },
                {
                    key:
            'V<sub>NO</sub> <span class="small">höchste Reisegeschwindigkeit</span>',
                    value: '260 km/h'
                },
                {
                    key: 'V<sub>FE</sub> <span class="small">mit Klappen</span>',
                    value: '170 km/h'
                },
                {
                    key:
            'V<sub>NE</sub> <span class="small">niemals überschreiten</span>',
                    value: '308 km/h'
                },
                {
                    key: 'V<sub>TGT</sub> <span class="small">Landeanflug</span>',
                    value: '130 km/h'
                },
                { key: '&nbsp;', value: '' },
                { key: 'Seitenwind', description: '', value: '40 km/h MAX' }
            ]
        },
        {
            title: 'Power Settings',
            type: 'table',
            tables: [
                {
                    header: ['rpm', 'l/h', 'TAS km/h', 'h:min', 'km'],
                    rows: [
                        { data: ['2400', '28,0', '198', '3:33', '780'] },
                        { data: ['2500', '31,0', '212', '3:19', '750'] },
                        { data: ['2600', '34,0', '221', '3:08', '715'] },
                        { data: ['2700', '36,5', '230', '3:00', '690'] }
                    ]
                }
            ]
        },
        {
            title: 'Technische Daten',
            type: 'table',
            data: [
                { key: 'Hersteller', description: '', value: 'Robin Aircraft' },
                { key: 'Spannweite', description: '', value: '8,72 m' },
                { key: 'Länge', description: '', value: '6,96 m' },
                { key: 'Leistung', description: '', value: '180 PS' },
                { key: 'Haupttank', description: '', value: '100 Liter' },
                { key: 'Reservetank', description: '', value: '50 Liter' }
            ]
        },
        {
            title: 'Wartung',
            type: 'table',
            data: [{ key: 'Öl min/max', description: '', value: '3,8 - 7,6 Liter' }]
        }
    ],
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EJEK%2FD-EJEK%20Flughandbuch.pdf?alt=media&token=a10cd3be-d756-4669-b35e-1b3215ba4e53',
            name: 'Flughandbuch'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EJEK%2FD-EJEK%20Platzrunde.pdf?alt=media&token=111598ef-9311-4b69-9ad5-57a5b37939f1',
            name: 'Platzrunde'
        }
    ]
});

planes.push({
    registration: 'D-EMPB',
    type: 'Robin Aircraft DR400',
    image: 'planes/d-empb.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/cjK4chRP',
    cards: [
        {
            title: 'Geschwindigkeiten',
            type: 'table',
            data: [
                {
                    key: 'V<sub>R</sub> <span class="small">Abheben</span>',
                    value: '100 km/h'
                },
                {
                    key: 'V<sub>X</sub> <span class="small">bester Steigwinkel</span>',
                    value: '130 km/h - Klappen 1'
                },
                {
                    key: 'V<sub>Y</sub> <span class="small">beste Steigrate</span>',
                    value: '160 km/h'
                },
                {
                    key: 'V<sub>BG</sub> <span class="small">bestes Gleiten</span>',
                    description: '',
                    value: '1:9,3 bei 145 km/h'
                },
                {
                    key: 'V<sub>A</sub> <span class="small">voller Ruderausschlag</span>',
                    value: '215 km/h'
                },
                {
                    key:
            'V<sub>NO</sub> <span class="small">höchste Reisegeschwindigkeit</span>',
                    value: '260 km/h'
                },
                {
                    key: 'V<sub>FE</sub> <span class="small">mit Klappen</span>',
                    value: '170 km/h'
                },
                {
                    key:
            'V<sub>NE</sub> <span class="small">niemals überschreiten</span>',
                    value: '308 km/h'
                },
                {
                    key: 'V<sub>TGT</sub> <span class="small">Landeanflug</span>',
                    value: '130 km/h'
                },
                { key: '&nbsp;', value: '' },
                { key: 'Seitenwind', description: '', value: '40 km/h MAX' }
            ]
        },
        {
            title: 'Power Settings',
            type: 'table',
            tables: [
                {
                    header: ['rpm', 'l/h', 'TAS km/h', 'h:min', 'km'],
                    rows: [
                        { data: ['2400', '28,0', '198', '3:33', '780'] },
                        { data: ['2500', '31,0', '212', '3:19', '750'] },
                        { data: ['2600', '34,0', '221', '3:08', '715'] },
                        { data: ['2700', '36,5', '230', '3:00', '690'] }
                    ]
                }
            ]
        },
        {
            title: 'Technische Daten',
            type: 'table',
            data: [
                { key: 'Hersteller', description: '', value: 'Robin Aircraft' },
                { key: 'Spannweite', description: '', value: '8,72 m' },
                { key: 'Länge', description: '', value: '6,96 m' },
                { key: 'Leistung', description: '', value: '180 PS' },
                { key: 'Haupttank', description: '', value: '100 Liter' },
                { key: 'Reservetank', description: '', value: '50 Liter' }
            ]
        },
        {
            title: 'Wartung',
            type: 'table',
            data: [{ key: 'Öl min/max', description: '', value: '3,8 - 7,6 Liter' }]
        },
        {
            title: 'Schleppen',
            type: 'html',
            content:
        'Sprit unter 20 Liter > 40 Liter nachtanken !!!' +
        '<div class="towing">\n' +
        '<ul>Start:\n' +
        '<li>Flaps 1 - Fuel Pump ON - Schleppen immer Vollgas</li>\n' +
        '<li>Abheben 90 km/h</li>\n' +
        '<li>Beschleunigen auf 120 km/h</li>\n' +
        '</ul>\n' +
        '<ul>In der Luft:\n' +
        '<li>Schleppen 120-130 km/h</li>\n' +
        '<li>Nach Hangkante/Schlepp stabil: Flaps 0 - Fuel Pump Off\n' +
        '</ul>\n' +
        '<ul>Abstieg:\n' +
        '<li>Absteigen mit RPM zwischem rotem Bereich und rotem Strich</li>\n' +
        '<li>Endteil Seilabwurf: Nicht zu tief, linke Seite Bahn, Seilabwurf wenn Remo über Weg</li>\n' +
        '<li>Aufschwung: Speed in weißen Bereich reduzieren, Flaps 1, Fuel Pump ON, Vergaservorwärmung (bei Bedarf)</li>\n' +
        '</ul>\n' +
        '<ul>Bei hohen Außentemperaturen:\n' +
        '<li>Von Anfang an mit 130-150 km/h schleppen</li>\n' +
        '<li>100% AVGAS tanken</li>\n' +
        '<li>Ölstand regelmäßig kontrollieren und auffüllen</li>\n' +
        '</ul>\t\t\n' +
        '</div>'
        }
    ],
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EMPB%2FD-EMPB%20Airbox.pdf?alt=media&token=a32fe06b-01a9-4794-886f-34fabb697f4f',
            name: 'Airbox'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EMPB%2FD-EMPB%20Platzrunde.pdf?alt=media&token=268f901a-94d6-43be-bfc0-fe00d9dc6d69',
            name: 'Platzrunde'
        }
    ]
});

planes.push({
    registration: 'D-EFGJ',
    type: 'Diamond Katana DV-20',
    image: 'planes/d-efgj.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/2kFeJzFb1',
    cards: [
        {
            title: 'Geschwindigkeiten',
            type: 'table',
            data: [
                {
                    key: 'V<sub>R</sub> <span class="small">Abheben</span>',
                    value: '51 kts'
                },
                {
                    key: 'V<sub>X</sub> <span class="small">bester Steigwinkel</span>',
                    value: '58 kts - Klappen TO'
                },
                {
                    key: 'V<sub>Y</sub> <span class="small">beste Steigrate</span>',
                    value: '65 kts - Klappen TO'
                },
                {
                    key: 'V<sub>BG</sub> <span class="small">bestes Gleiten</span>',
                    description: '',
                    value: '1:14 bei 70 kts - TO'
                },
                {
                    key: 'V<sub>A</sub> <span class="small">voller Ruderausschlag</span>',
                    value: '104 kts'
                },
                {
                    key:
            'V<sub>NO</sub> <span class="small">höchste Reisegeschwindigkeit</span>',
                    value: '117 kts'
                },
                {
                    key: 'V<sub>FE</sub> <span class="small">mit Klappen</span>',
                    value: '81 kts'
                },
                {
                    key:
            'V<sub>NE</sub> <span class="small">niemals überschreiten</span>',
                    value: '161 kts'
                },
                {
                    key: 'V<sub>TGT</sub> <span class="small">Landeanflug</span>',
                    value: '60 kts'
                },
                { key: '&nbsp;', value: '' },
                { key: 'Seitenwind', description: '', value: '15 kts - 27 km/h' }
            ]
        },
        {
            title: 'Power Settings',
            type: 'table',
            tables: [
                {
                    header: ['ft', '%', 'inHG', 'rmp', 'l/h'],
                    rows: [
                        { data: ['0', '105', '29,7', '2380', '30,0'], classes: 'red' },
                        { data: ['2000', '95', '27,7', '2260', '26,8'] },
                        { data: ['4000', '85', '25,7', '2260', '25,2'] },
                        { data: ['4000', '75', '24,3', '2260', '19,6'] },
                        { data: ['4000', '65', '23,3', '2100', '16,8'] },
                        { data: ['4000', '55', '23,3', '1900', '15,6'] }
                    ]
                }
            ],
            content:
        '<i>105% Leistung dürfen nur kurzzeitig beim Start genutzt werden!<br /><br />Normaler Reiseflug 23,5 inHG – 2100 rpm<br /><br />Höhere Flughöhen erhöhen den Verbrauch!</i>'
        },
        {
            title: 'Technische Daten',
            type: 'table',
            data: [
                { key: 'Hersteller', description: '', value: 'Diamond Aircraft' },
                { key: 'Spannweite', description: '', value: '10,78 m' },
                { key: 'Länge', description: '', value: '7,28 m' },
                { key: 'Leistung', description: '', value: '100 PS bei 2385 rpm' },
                { key: 'Tank', description: '', value: '77 Liter' }
            ]
        },
        {
            title: 'Wartung',
            type: 'table',
            data: [
                { key: 'Öl min/max', description: '', value: '3,0 - 3,4 Liter' },
                { key: 'Kühlmittel min/max', description: '', value: '2,4 - 2,5 Liter' }
            ]
        }
    ],
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EFGJ%2FD-EFGJ%20Flughandbuch.pdf?alt=media&token=1bdde68c-1a30-4d4b-8f60-b406802d74c7',
            name: 'Flughandbuch'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EFGJ%2FD-EFGJ%20Garmin%20GNS%20430%20Schnell%C3%BCbersicht.pdf?alt=media&token=3db63175-91e0-4035-88eb-6b95f1ba038c',
            name: 'Garmin GNS 430'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EFGJ%2FD-EFGJ%20Platzrunde.pdf?alt=media&token=3c2212ca-5c10-4eb3-a64d-18432a00602c',
            name: 'Platzrunde'
        }
    ]
});

planes.push({
    registration: 'OE-CIE',
    type: 'Diamond Katana DV-20',
    image: 'planes/oe-cie.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/hGdohwY2',
    cards: [
        {
            title: 'Geschwindigkeiten',
            type: 'table',
            data: [
                {
                    key: 'V<sub>R</sub> <span class="small">Abheben</span>',
                    value: '51 kts'
                },
                {
                    key: 'V<sub>X</sub> <span class="small">bester Steigwinkel</span>',
                    value: '58 kts - Klappen TO'
                },
                {
                    key: 'V<sub>Y</sub> <span class="small">beste Steigrate</span>',
                    value: '65 kts - Klappen TO'
                },
                {
                    key: 'V<sub>BG</sub> <span class="small">bestes Gleiten</span>',
                    description: '',
                    value: '1:14 bei 70 kts - TO'
                },
                {
                    key: 'V<sub>A</sub> <span class="small">voller Ruderausschlag</span>',
                    value: '104 kts'
                },
                {
                    key:
                        'V<sub>NO</sub> <span class="small">höchste Reisegeschwindigkeit</span>',
                    value: '117 kts'
                },
                {
                    key: 'V<sub>FE</sub> <span class="small">mit Klappen</span>',
                    value: '81 kts'
                },
                {
                    key:
                        'V<sub>NE</sub> <span class="small">niemals überschreiten</span>',
                    value: '161 kts'
                },
                {
                    key: 'V<sub>TGT</sub> <span class="small">Landeanflug</span>',
                    value: '60 kts'
                },
                { key: '&nbsp;', value: '' },
                { key: 'Seitenwind', description: '', value: '15 kts - 27 km/h' }
            ]
        },
        {
            title: 'Power Settings',
            type: 'table',
            tables: [
                {
                    header: ['ft', '%', 'inHG', 'rmp', 'l/h'],
                    rows: [
                        { data: ['0', '105', '29,7', '2380', '30,0'], classes: 'red' },
                        { data: ['2000', '95', '27,7', '2260', '26,8'] },
                        { data: ['4000', '85', '25,7', '2260', '25,2'] },
                        { data: ['4000', '75', '24,3', '2260', '19,6'] },
                        { data: ['4000', '65', '23,3', '2100', '16,8'] },
                        { data: ['4000', '55', '23,3', '1900', '15,6'] }
                    ]
                }
            ],
            content:
                '<i>105% Leistung dürfen nur kurzzeitig beim Start genutzt werden!<br /><br />Normaler Reiseflug 23,5 inHG – 2100 rpm<br /><br />Höhere Flughöhen erhöhen den Verbrauch!</i>'
        },
        {
            title: 'Technische Daten',
            type: 'table',
            data: [
                { key: 'Hersteller', description: '', value: 'Diamond Aircraft' },
                { key: 'Spannweite', description: '', value: '10,78 m' },
                { key: 'Länge', description: '', value: '7,28 m' },
                { key: 'Leistung', description: '', value: '100 PS bei 2385 rpm' },
                { key: 'Tank', description: '', value: '77 Liter' }
            ]
        },
        {
            title: 'Wartung',
            type: 'table',
            data: [
                { key: 'Öl min/max', description: '', value: '3,0 - 3,4 Liter' },
                { key: 'Kühlmittel min/max', description: '', value: '2,4 - 2,5 Liter' }
            ]
        }
    ],
    downloads: [
        {
            link:
                'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/OE-CIE%2FGarmin%20GTN%20650%20(englisch).pdf?alt=media&token=57138983-de6e-4404-93c8-33693817262f',
            name: 'Garmin GTN 650 (englisch)'
        },
        {
            link:
                'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EFGJ%2FD-EFGJ%20Platzrunde.pdf?alt=media&token=3c2212ca-5c10-4eb3-a64d-18432a00602c',
            name: 'Platzrunde'
        }
    ]
});

planes.push({
    registration: 'D-KHFE',
    type: 'Scheibe SF 25 B',
    image: 'planes/d-khfe.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/g59WMAwh',
    cards: [
        {
            title: 'Geschwindigkeiten',
            type: 'table',
            data: [
                {
                    key: 'V<sub>R</sub> <span class="small">Abheben</span>',
                    value: '90 km/h'
                },
                {
                    key: 'V<sub>Y</sub> <span class="small">beste Steigrate</span>',
                    value: '90 km/h'
                },
                {
                    key: 'V<sub>C</sub> <span class="small"></span>',
                    value: '125 km/h bei 2200rpm'
                },
                {
                    key: 'V<sub>BG</sub> <span class="small">bestes Gleiten</span>',
                    description: '',
                    value: '1:21 bei 85 km/h'
                },
                {
                    key: 'V<sub>MS</sub> <span class="small">geringstes Sinken</span>',
                    description: '',
                    value: '1,0 m/s bei 70 km/h'
                },
                {
                    key: 'V<sub>A</sub> <span class="small">voller Ruderausschlag</span>',
                    value: '150 km/h'
                },
                {
                    key:
            'V<sub>NO</sub> <span class="small">höchste Reisegeschwindigkeit</span>',
                    value: '145 km/h / 2600rpm'
                },
                {
                    key: 'V<sub>FE</sub> <span class="small">mit Klappen</span>',
                    value: '190 km/h'
                },
                {
                    key:
            'V<sub>NE</sub> <span class="small">niemals überschreiten</span>',
                    value: '190 km/h'
                },
                {
                    key: 'V<sub>TGT</sub> <span class="small">Landeanflug</span>',
                    value: '85 km/h'
                },
                {
                    key: 'V<sub>TD</sub> <span class="small">Aufsetzen</span>',
                    value: '65 km/h'
                },
                { key: '&nbsp;', value: '' },
                { key: 'Seitenwind', description: '', value: '25 km/h' }
            ]
        },
        {
            title: 'Power Settings',
            type: 'table',
            tables: [
                {
                    header: ['rpm', 'l/h', 'km/h', 'h:min', 'km'],
                    rows: [
                        { data: ['2200', '9,2', '125', '3:28', '430'] },
                        { data: ['2400', '10,9', '135', '2:56', '395'] },
                        { data: ['2600', '12,7', '145', '2:31', '365'] }
                    ]
                }
            ]
        },
        {
            title: 'Technische Daten',
            type: 'table',
            data: [
                { key: 'Hersteller', description: '', value: 'Scheibe' },
                { key: 'Spannweite', description: '', value: '15,30 m' },
                { key: 'Länge', description: '', value: '7,50 m' },
                { key: 'Leistung', description: '', value: '54 PS bei 3000 rpm' },
                { key: 'Tank', description: '', value: '32 Liter' }
            ]
        },
        {
            title: 'Wartung',
            type: 'table',
            data: [{ key: 'Öl min/max', description: '', value: '1,5 - 2,5 Liter' }]
        }
    ],
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-KHFE%2FD-KHFE%20Flughandbuch.pdf?alt=media&token=e108f97c-5860-4fe0-9053-2c970a247a3e',
            name: 'Flughandbuch'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-KHFE%2FD-KHFE%20Betriebshandbuch%20Motor.pdf?alt=media&token=506805e8-20db-4c0d-bb3b-e50bc35b6d75',
            name: 'Betriebshandbuch Motor'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-KHFE%2FD-KHFE%20Wartungshandbuch.pdf?alt=media&token=8433843e-435e-409f-8c38-4a3c53f0cf34',
            name: 'Wartungshandbuch'
        }
    ]
});

planes.push({
    registration: 'D-KBGN',
    type: 'Grob G109 B',
    image: 'planes/d-kbgn.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/wMDBAxga',
    cards: [
        {
            title: 'Geschwindigkeiten',
            type: 'table',
            data: [
                {
                    key: 'V<sub>R</sub> <span class="small">Abheben</span>',
                    value: '85 km/h'
                },
                {
                    key: 'V<sub>X</sub> <span class="small">bester Steigwinkel</span>',
                    value: '90 km/h'
                },
                {
                    key: 'V<sub>Y</sub> <span class="small">beste Steigrate</span>',
                    value: '110 km/h'
                },
                {
                    key: 'V<sub>BG</sub> <span class="small">bestes Gleiten</span>',
                    description: '',
                    value: '115 km/h'
                },
                {
                    key: 'V<sub>MS</sub> <span class="small">geringstes Sinken</span>',
                    description: '',
                    value: '1,1 m/s bei 108 km/h'
                },
                {
                    key: 'V<sub>A</sub> <span class="small">voller Ruderausschlag</span>',
                    value: '170 km/h'
                },
                {
                    key:
            'V<sub>NO</sub> <span class="small">höchste Reisegeschwindigkeit</span>',
                    value: '170 km/h'
                },
                {
                    key: 'V<sub>FE</sub> <span class="small">mit Klappen</span>',
                    value: '240 km/h'
                },
                {
                    key:
            'V<sub>NE</sub> <span class="small">niemals überschreiten</span>',
                    value: '240 km/h'
                },
                {
                    key: 'V<sub>TGT</sub> <span class="small">Landeanflug</span>',
                    value: '115 km/h'
                },
                {
                    key: 'V<sub>TD</sub> <span class="small">Aufsetzen</span>',
                    value: '75 - 85 km/h'
                },
                { key: '&nbsp;', value: '' },
                { key: 'Seitenwind', description: '', value: '20 km/h' }
            ]
        },
        {
            title: 'Power Settings',
            type: 'table',
            tables: [
                {
                    header: ['%', 'inHG', 'rpm', 'l/h', 'IAS', 'TAS'],
                    rows: [
                        { data: ['100', '40,0', '3000', '32,3', '', ''], classes: 'red' },
                        { data: ['75', '36,0', '2700', '23,5', '207', '231'] },
                        { data: ['65', '33,0', '2600', '19,8', '190', '212'] },
                        { data: ['55', '30,0', '2600', '16,7', '185', '206'] },
                        { data: ['50', '28,5', '2600', '15,2', '175', '185'] },
                        { data: ['45', '25,0', '2600', '14,2', '162', '181'] }
                    ]
                }
            ],
            content:
        '<i>100% Leistung dürfen nur kurzzeitig beim Start genutzt werden!<br /><br />IAS und TAS beziehen sich auf 6000ft</i>'
        },
        {
            title: 'Technische Daten',
            type: 'table',
            data: [
                { key: 'Hersteller', description: '', value: 'Grob' },
                { key: 'Spannweite', description: '', value: '17,40 m' },
                { key: 'Länge', description: '', value: '8,10 m' },
                { key: 'Leistung', description: '', value: '130 PS bei 3000 rpm' },
                { key: 'Tank', description: '', value: '98 Liter' }
            ]
        },
        {
            title: 'Wartung',
            type: 'table',
            data: [
                { key: 'Öl min/max', description: '', value: '2,75 - 3,5 Liter' },
                { key: 'Kühlmittel min/max', description: '', value: '3,3 Liter' }
            ]
        },
        {
            title: 'Schleppen',
            type: 'html',
            content:
        '<div class="towing">\n' +
        '<ul>Start:\n' +
        '<li>Abheben mit 90 km/h</li>\n' +
        '<li>Beschleunigen auf 100 km/h dann auf 2800 RPM reduzieren</li>\n' +
        '</ul>\n' +
        '<ul>In der Luft:\n' +
        '<li>Schleppen mit 110 km/h</li>\n' +
        '<li>In Sicherheitshöhe RPM zurück auf 2600-2700</li>\n' +
        '</ul>\n' +
        '<ul>Abstieg:\n' +
        '<li>Absteigen mit Ladedruck von 20 inch und 2600 RPM</li>\n' +
        '<li>Endteil für Seilabwurf mit 2600 RPM</li>\n' +
        '<li>Nach dem Aufschwung, RPM auf 3000 für die Landung</li>\n' +
        '</ul>\n' +
        '\n' +
        '<ul>Bei hohen Außentemperaturen:\n' +
        '    <li>Von Anfang an mit 110-120 km/h schleppen</li>\n' +
        '</ul>\n' +
        '</div>'
        }
    ],
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-KBGN%2FD-KBGN%20Flughandbuch.pdf?alt=media&token=fe506e70-033c-4cd0-8828-fd108d9af1ee',
            name: 'Flughandbuch'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-KBGN%2FD-KBGN%20Motorhandbuch.pdf?alt=media&token=81000e2d-cf4a-405d-9e65-ff0ae276ecc7',
            name: 'Motorhandbuch'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-KBGN%2FD-KBGN%20Schlepphandbuch.pdf?alt=media&token=5a5667ba-1731-4036-a91a-b1ebc684ba93',
            name: 'Schlepphandbuch'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-KBGN%2FD-KBGN%20Einf%C3%BChrung.ppt?alt=media&token=239a696e-3924-4ade-9393-251541f304d2',
            name: 'Einführung (Powerpoint)'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-KBGN%2FD-KBGN%20Airbox.pdf?alt=media&token=64b0a8f5-7194-41f8-9576-84fd46c5f75e',
            name: 'Airbox'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-KBGN%2FD-KBGN%20Platzrunde.pdf?alt=media&token=c55cd47f-a389-4daa-ad9f-57b33b834d74',
            name: 'Platzrunde'
        }
    ]
});

planes.push({
    registration: 'D-EHHI',
    type: 'Christen A-1 Husky',
    image: 'planes/d-ehhi.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/2DD7nPetp',
    cards: [
        {
            title: 'Geschwindigkeiten',
            type: 'table',
            data: [
                {
                    key: 'V<sub>R</sub> <span class="small">Abheben</span>',
                    value: '55 MPH - 88 km/h'
                },
                {
                    key: 'V<sub>X</sub> <span class="small">bester Steigwinkel</span>',
                    value: '58 MPH - 93 km/h'
                },
                {
                    key: 'V<sub>Y</sub> <span class="small">beste Steigrate</span>',
                    value: '73 MPH - 117 km/h'
                },
                {
                    key: 'V<sub>BG</sub> <span class="small">bestes Gleiten</span>',
                    description: '',
                    value: '72 MPH - 116 km/h'
                },
                {
                    key: 'V<sub>A</sub> <span class="small">voller Ruderausschlag</span>',
                    value: '94 MPH - 150 km/h'
                },
                {
                    key:
            'V<sub>NO</sub> <span class="small">höchste Reisegeschwindigkeit</span>',
                    value: '119 MPH - 192 km/h'
                },
                {
                    key: 'V<sub>FE</sub> <span class="small">mit Klappen</span>',
                    value: '73 MPH - 117 km/h'
                },
                {
                    key:
            'V<sub>NE</sub> <span class="small">niemals überschreiten</span>',
                    value: '152 MPH - 245 km/h'
                },
                {
                    key: 'V<sub>TGT</sub> <span class="small">Landeanflug</span>',
                    value: '58 MPH - 93 km/h'
                },
                { key: '&nbsp;', value: '' },
                { key: 'Seitenwind', description: '', value: '15 MPH - 24 km/h' }
            ]
        },
        {
            title: 'Technische Daten',
            type: 'table',
            data: [
                { key: 'Hersteller', description: '', value: 'Christen Industries' },
                { key: 'Spannweite', description: '', value: '10,81 m' },
                { key: 'Länge', description: '', value: '6,88 m' },
                { key: 'Leistung', description: '', value: '180 PS bei 2700 rpm' },
                { key: 'Tank', description: '', value: '2 x 95 Liter' }
            ]
        },
        {
            title: 'Wartung',
            type: 'table',
            data: [
                {
                    key: 'Öl min/max',
                    description: '',
                    value: 'Zwischen 5 - 7 auf der Skala'
                }
            ]
        },
        {
            title: 'Schleppen',
            type: 'html',
            content:
        '<div class="towing">\n' +
        '<ul>Vor dem Start\n' +
        '<li>Seilauszug: 0m</li>\n' +
        '<li>Drehzahl: voll</li>\n' +
        '<li>Flaps: 1. Stufe</li>\n' +
        '</ul>\n' +
        '\n' +
        '<ul>Anziehen:\n' +
        '<li>Seilauszug ca. 50m</li>\n' +
        '<li>Roter Teil vom Seil im Spiegel sichtbar?</li>\n' +
        '</ul>\n' +
        '\n' +
        '<ul>Schlepp:\n' +
        '<li>Beschleunigen bis Ende weißer Bereich (70-75mph)</li>\n' +
        '<li>Drehzahl: auf 2500 RPM zurück</li>\n' +
        '<li>stabiler Schlepp: Flaps 0</li>\n' +
        '<li>Geschwindigkeit: 70-80mph</li>\n' +
        '</ul>\n' +
        '\n' +
        '<ul>Ausklinken:\n' +
        '<li>Seileinzug: Ein</li>\n' +
        '</ul>\n' +
        '\n' +
        '<ul>Abstieg:\n' +
        '<li>Drehzahl: 2300 RPM</li>\n' +
        '<li>Ladedruck: 18-20 inch</li>\n' +
        '<li>Geschwindigkeit im grünen Bereich, bei ruhiger Luft auch leicht im gelben Bereich möglich</li>\n' +
        '</ul>\n' +
        '\n' +
        '<ul>Vor der Landung:\n' +
        '<li>Im Spiegel prüfen ob das Seil komplett eingezogen ist</li>\n' +
        '</ul>\n' +
        '\n' +
        '<ul>Bei hohen Außentemperaturen:\n' +
        '<li>Von Anfang an mit 75-85mph schleppen</li>\n' +
        '<li>100% AVGAS tanken</li>\n' +
        '<li>Ölstand regelmäßig kontrollieren und auffüllen</li>\n' +
        '</ul>\n' +
        '</div>'
        }
    ],
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EHHI%2FD-EHHI%20Flughandbuch.pdf?alt=media&token=5908126f-fad0-4c6d-9188-d466078f3cc8',
            name: 'Flughandbuch'
        }
    ]
});

planes.push({
    registration: 'D-EAEK',
    type: 'Cessna 182 RG',
    image: 'planes/d-eaek.jpg',
    camo: '',
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EAEK%2FD-EAEK%20Flughandbuch.pdf?alt=media&token=4b51938a-e7ea-45a0-b6fb-c520bb2eb17a',
            name: 'Flughandbuch'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EAEK%2FD-EAEK%20Power%20Settings.pdf?alt=media&token=7ccbcc0a-8a68-4313-a717-62a2aa44fa76',
            name: 'Power Settings'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-EAEK%2FD-EAEK%20NASA%20Moving%20Map%20Manual.pdf?alt=media&token=8aa7e8d0-09f8-469d-a217-abef4056ae78',
            name: 'NASA Moving Map'
        }
    ]
});

planes.push({
    registration: 'D-ENEJ',
    type: 'Socata MS 880 - Morane',
    image: 'planes/d-enej.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/3AFypSR6A',
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-ENEJ%2FD-ENEJ%20Flughandbuch.pdf?alt=media&token=c3f5d5f7-8597-42e6-81fa-0e3c33538a18',
            name: 'Flughandbuch'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-ENEJ%2FD-ENEJ%20Repair%20Manual.pdf?alt=media&token=23ad7e4e-f4b0-4047-89a2-42b97e64f2f7',
            name: 'Repair Manual'
        },
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-ENEJ%2FD-ENEJ%20Checkliste.pdf?alt=media&token=c7b3757f-995a-42f6-b886-399e02f6dd32',
            name: 'Checkliste'
        }
    ]
});

planes.push({
    registration: 'D-0699',
    type: 'Rolladen Schneider LS 4-b',
    image: 'planes/d-0699.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/jgD2qGDX',
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-0699%2FD-0699%20Flughandbuch.pdf?alt=media&token=4ba4776e-8cef-4123-8d22-623f7b95a3ec',
            name: 'Flughandbuch'
        }
    ]
});

planes.push({
    registration: 'D-5491',
    type: 'Alexander Schleicher ASK 21',
    image: 'planes/d-5491.jpg',
    camo: ''
});

planes.push({
    registration: 'D-0611',
    type: 'DG 101 ELAN',
    image: 'planes/d-0611.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/C3bzQp3y',
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-0611%2FD-0611%20Flughandbuch.pdf?alt=media&token=7af1acfa-80ff-4f1b-b6bc-27ad3208e7d6',
            name: 'Flughandbuch'
        }
    ]
});

planes.push({
    registration: 'D-0801',
    type: 'DG 1000S',
    image: 'planes/d-0801.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/A7QbJjfx',
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-0801%2FD-0801%20Flughandbuch.pdf?alt=media&token=7d47c992-6ce1-41b1-b618-b3aae7ecbf13',
            name: 'Flughandbuch'
        }
    ]
});

planes.push({
    registration: 'D-2171',
    type: 'Rolladen Schneider LS 4-b',
    image: 'planes/d-2171.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/2RLxbpgr',
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-2171%2FD-2171%20Flughandbuch.pdf?alt=media&token=5f54a8b0-0f15-41c3-89f7-23f4b899438f',
            name: 'Flughandbuch'
        }
    ]
});

planes.push({
    registration: 'D-2451',
    type: 'Glasflügel Club Libelle 205',
    image: 'planes/d-2451.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/2ZFGANaRi'
});

planes.push({
    registration: 'D-4832',
    type: 'Grob Astir CS Jeans',
    image: 'planes/d-4832.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/2L3s5Tsjp'
});

planes.push({
    registration: 'D-5628',
    type: 'Alexander Schleicher ASK 21',
    image: 'planes/d-5628.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/cnHujnSF'
});

planes.push({
    registration: 'D-7078',
    type: 'Grunau Baby II b',
    image: 'planes/d-7078.jpg',
    camo: ''
});

planes.push({
    registration: 'D-7517',
    type: 'Grob Astir CS Jeans',
    image: 'planes/d-7517.jpg',
    camo: '',
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-7517%2FE-Vario%20VW3SG.pdf?alt=media&token=06e70e70-50d3-4b6c-9886-502a1b7681c3',
            name: 'E-Vario VW3SG'
        }
    ]
});

planes.push({
    registration: 'D-KDUE',
    type: 'Schempp-Hirth Arcus M',
    image: 'planes/d-kdue.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/2MBRTSBuC',
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-KDUE%2FD-KDUE%20Flughandbuch.pdf?alt=media&token=2ccebc59-acd8-427f-92b1-d7e47fbaafe2',
            name: 'Flughandbuch'
        }
    ]
});

planes.push({
    registration: 'D-KKVO',
    type: 'Schempp-Hirth Duo Discus T',
    image: 'planes/d-kkvo.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/2dnTEsXy5'
});

planes.push({
    registration: 'D-KLVD',
    type: 'Schempp-Hirth Discus 2cT',
    image: 'planes/d-klvd.jpg',
    camo: 'https://402d345c48.mpx-easystar.de/camoproxy/bHgDTHZ8',
    downloads: [
        {
            link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/D-KLVD%2FD-KLVD%20Flughandbuch.pdf?alt=media&token=b2df353d-2966-4d5b-b045-519c6640631c',
            name: 'Flughandbuch'
        }
    ]
});

planes.sort((a: any, b: any): any => {
    if (a.registration < b.registration) {
        return -1;
    }

    if (a.registration > b.registration) {
        return 1;
    }

    return 0;
});

export default planes;
