
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import * as fi from '@/data/fi.ts';

@Component({
    computed: {
        ...mapGetters({
            students: 'fluglehrerMotorflug/pilots'
        })
    }
})
export default class ImprintPage extends Vue {
  userLoaded: boolean = false;
  dialog: boolean = false;

  private fi: any = fi.default;
}
