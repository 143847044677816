
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as moment from 'moment';

/* eslint-disable no-unused-vars */
const lightbox2 = require('lightbox2/dist/js/lightbox');
const lightbox2Css = require('lightbox2/dist/css/lightbox.css');
/* eslint-enable no-unused-vars */

@Component({})
export default class Card extends Vue {
  @Prop() public card!: any;
  @Prop() public id!: string;
  @Prop() public showRemoveButton!: boolean;
  @Prop() public removeCallback!: string;

  removeDialog: boolean = false;

  showRemoveDialog () {
      this.removeDialog = true;
  }

  remove () {
      this.$store.dispatch(this.removeCallback, this.id);
  }

  formatDate (timestamp: any): string {
      return moment.unix(timestamp.seconds).format('DD.MM.YYYY');
  }
}
