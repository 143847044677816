
import Vue from 'vue';
import Component from 'vue-class-component';
import Card from '@/components/card/Card.vue';
import { mapGetters } from 'vuex';

@Component({
    components: {
        Card
    },
    computed: {
        ...mapGetters({
            towing: 'statistics/towing',
            towingMax: 'statistics/towingMax',
            flightSchool: 'statistics/flightSchool',
            flightSchoolMax: 'statistics/flightSchoolMax',
            planes: 'statistics/planes',
            planesMax: 'statistics/planesMax',
            guestFlights: 'statistics/guestFlights',
            guestFlightsMax: 'statistics/guestFlightsMax',
            guestFlightsGlider: 'statistics/guestFlightsGlider',
            guestFlightsGliderMax: 'statistics/guestFlightsGliderMax'
        })
    }
})
export default class StatisticsPage extends Vue {}
