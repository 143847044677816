
import Vue from 'vue';
import Component from 'vue-class-component';
import Card from '@/components/card/Card.vue';
import * as loadedEmergencies from '@/data/emergency.ts';

@Component({
    components: {
        Card
    }
})
export default class EmergencyPage extends Vue {
  private emergency: any = loadedEmergencies.default;
}
