
import Axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import Downloads from '@/components/downloads/Downloads.vue';
import * as loadedPlanes from '@/data/planes.ts';

@Component({
    components: {
        Card,
        Downloads
    }
})
export default class PlanesPage extends Vue {
  private baseURL: string = 'https://402d345c48.mpx-easystar.de';
  // private baseURL: string = 'http://lscappbackend.development';

  private planes: any = loadedPlanes.default;
  private planesFiltered: any = loadedPlanes.default;
  private planeSelected: any = false;
  private showCAMO: boolean = false;
  private spinner: boolean = false;
  private showBlocked: string = '';
  private showBlockedIsBlocked: boolean = false;

  private searchText: string = '';

  @Watch('searchText')
  private onSearchTextChange (): void {
      const tmpPlanes: any = [];
      const regex = new RegExp('.*' + this.searchText + '.*', 'i');

      for (const plane of this.planes) {
          if (
              this.searchText === null ||
        this.searchText === '' ||
        plane.registration.match(regex) !== null ||
        plane.type.match(regex) !== null
          ) {
              tmpPlanes.push(plane);
          }
      }

      this.planesFiltered = tmpPlanes;
  }

  private selectPlane (index: any) {
      this.$router.push({ path: '/planes', query: { plane: index } });
  }

  private checkCAMO () {
      this.showCAMO = true;
  }

  private checkBlocked (registration: string) {
      const self = this;
      self.spinner = true;
      Axios.get(this.baseURL + '/api/reservation?reg=' + registration)
          .then(response => {
              console.log(response.data);
              self.showBlocked = response.data.status;
              self.showBlockedIsBlocked = response.data.isBlocked;
              self.spinner = false;
          })
          .catch(error => {
              self.spinner = false;
              console.log(error);
          });
  }

  private created () {
      if (this.$route.query.plane !== undefined) {
          for (const singlePlane of this.planes) {
              if (singlePlane.registration === this.$route.query.plane.toString()) {
                  this.planeSelected = singlePlane;
              }
          }
      }
  }
}
