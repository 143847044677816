
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

@Component({
    components: {
    },
    computed: {
        ...mapGetters({
            present: 'corona/present',
            myEntriesToday: 'corona/myEntriesToday',
            min: 'corona/min'
        })
    }
})
export default class CoronaPage extends Vue {
    private dialog: boolean = false;
    private picker: string = '';
    private max: string = '';

    private showDialog () {
        const date = new Date();
        this.picker = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
        this.max = this.picker;
        this.dialog = true;
    }

    private store () {
        this.$store.dispatch('corona/update', this.picker).then(() => {
            this.dialog = false;
        });
    }

    private mounted () {
        this.$store.dispatch('corona/loadStatus');
        this.$store.dispatch('corona/checkDate');
    }
}
