
import Axios from 'axios';
import moment from 'moment';
import Vue from 'vue';
import Component from 'vue-class-component';
import Card from '@/components/card/Card.vue';
import { Watch } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';

import { validationMixin } from 'vuelidate';
import {
    required,
    minLength
} from 'vuelidate/lib/validators';

@Component({
    components: {
        Card
    },
    directives: {
        mask
    },
    validations: {
        transponderCode: { required, minLength: minLength(20) },
        transponderRegistration: { required, minLength: minLength(5) },
        transponderOwner: { required },
        transponderDescription: { required },
        priceMOGAS: { required },
        priceAVGAS: { required },
        levelMOGAS: { required },
        levelAVGAS: { required }
    },
    mixins: [validationMixin]
})
export default class GasstationPage extends Vue {
  private baseURL: string = 'https://402d345c48.mpx-easystar.de';
  // private baseURL: string = 'http://lscappbackend.development';

  private isAdmin: boolean = false;
  private canSeeRechnungen: boolean = false;

  private spinner: boolean = false;
  private lastEntries: number = 15;
  private lastBillings: number = 15;

  private openBilling: any = [];
  private checkboxAllOpen: boolean = false;
  private checkboxSum: boolean = false;
  private checkboxStoreAddress: boolean = true;

  // Modals
  private dialog: boolean = false;
  private fuelLevel: boolean = false;
  private editPrices: boolean = false;
  private newTransponder: boolean = false;
  private editTransponder: boolean = false;

  private select: any = [
      { value: 15, text: 'Letzte 15 Tankvorgänge' },
      { value: 30, text: 'Letzte 30 Tankvorgänge' },
      { value: 50, text: 'Letzte 50 Tankvorgänge' },
      { value: 100, text: 'Letzte 100 Tankvorgänge' },
      { value: 500, text: 'Letzte 500 Tankvorgänge' },
      { value: 0, text: 'Alle Tankvorgänge' }
  ];

  private headers: any = [
      { text: 'Datum', value: 'name' },
      { text: 'Kennzeichen', value: 'calories' },
      { text: 'Sorte', value: 'fat' },
      { text: 'Menge', value: 'carbs' }
  ];

  private headersBillings: any = [
      { text: 'Datum', value: 'name' },
      { text: 'Kennzeichen', value: 'calories' },
      { text: 'Sorte', value: 'fat' },
      { text: 'Menge', value: 'carbs' }
  ];

  private items: any = [];
  private billings: any = [];
  private selectedBillings: any = [];

  // Variablen für Auswertung
  private airplanes: any = ['Alle Flugzeuge'];
  private selectedAirplane: string = 'Alle Flugzeuge';

  private fromDate: string = '';
  private fromDateFormatted: string = '';
  private fromModal: boolean = false;

  private untilDate: string = '';
  private untilDateFormatted: string = '';
  private untilModal: boolean = false;

  private today: string = moment().format('YYYY-MM-DD');

  private priceMOGAS: number = null;
  private priceAVGAS: number = null;

  private levelMOGAS: number = null;
  private levelAVGAS: number = null;

  private levelMOGASRead: number = null;
  private levelAVGASRead: number = null;

  private transponderCode: string = '';
  private transponderRegistration: string = '';
  private transponderOwner: string = '';
  private transponderAccount: string = '';
  private transponderDescription: string = '';
  private transponderMOGAS: boolean = false;
  private transponderAVGAS: boolean = false;

  private generateBilling (id: number) {
      const self = this;
      self.spinner = true;
      Axios.get(this.baseURL + '/api/gasstation/bill/' + id)
          .then(response => {
              self.spinner = false;
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                  'download',
                  'Rechnung ' + id + ' ' + moment().format('DD.MM.YYYY HH:mm') + '.pdf'
              );
              document.body.appendChild(link);
              link.click();
          })
          // eslint-disable-next-line handle-callback-err
          .catch(error => {
              self.spinner = false;
          });
  }

  @Watch('lastEntries')
  private onLastEntriesChange (): void {
      this.loadData();
  }

  @Watch('lastBillings')
  private onLastBillingsChange (): void {
      this.loadBillings();
  }

  @Watch('fromDate')
  private onFromDateChange (): void {
      this.fromDateFormatted = moment(this.fromDate).format('DD.MM.YYYY');
  }

  @Watch('untilDate')
  private onUntilDateChange (): void {
      this.untilDateFormatted = moment(this.untilDate).format('DD.MM.YYYY');
  }

  private loadData () {
      const self = this;
      self.spinner = true;
      Axios.get(this.baseURL + '/api/gasstation/overview/' + this.lastEntries)
          .then(response => {
              self.items = response.data;
              self.spinner = false;

              // Rechnungen laden
              self.loadBillings(false);
          })
          // eslint-disable-next-line handle-callback-err
          .catch(error => {
              self.spinner = false;
          });
  }

  private loadBillings (showSpinner: boolean = true) {
      const self = this;
      if (showSpinner) self.spinner = true;
      Axios.get(this.baseURL + '/api/gasstation/billings/' + this.lastBillings)
          .then(response => {
              self.billings = response.data;
              self.spinner = false;
          })
          // eslint-disable-next-line handle-callback-err
          .catch(error => {
              self.spinner = false;
          });
  }

  private loadBill (showSpinner: boolean = true) {
      const self = this;
      if (showSpinner) self.spinner = true;
      Axios.post(this.baseURL + '/api/gasstation/bill')
          .then(response => {
              self.spinner = false;
          })
          // eslint-disable-next-line handle-callback-err
          .catch(error => {
              self.spinner = false;
          });
  }

  private loadPlanes () {
      const self = this;
      Axios.get(this.baseURL + '/api/gasstation/planes').then(response => {
          self.airplanes.push(...response.data);
      });
  }

  private loadReport () {
      const self = this;
      self.spinner = true;

      Axios.get(
          this.baseURL +
        '/api/gasstation/report/' +
        moment(this.fromDate).unix() +
        '/' +
        moment(this.untilDate).unix() +
        '/' +
        btoa(this.selectedAirplane),
          { responseType: 'blob' }
      )
          .then(response => {
              self.spinner = false;
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                  'download',
                  'Auswertung Tankstelle ' +
            moment().format('DD.MM.YYYY HH:mm') +
            '.xls'
              );
              document.body.appendChild(link);
              link.click();
          })
          .catch(error => {
              console.log(error);
              self.spinner = false;
          });
  }

  private setPrices () {
      this.$v.$touch();
      if (
          this.$v.priceMOGAS.$invalid === false &&
      this.$v.priceAVGAS.$invalid === false
      ) {
          this.$store.dispatch('tankstelleUpdater/setUpdate', {
              type: 'setPrices',
              task: {
                  mogas: this.priceMOGAS,
                  avgas: this.priceAVGAS
              }
          });

          this.editPrices = false;
          this.priceMOGAS = null;
          this.priceAVGAS = null;
      }
  }

  private getFuelLevels () {
      const self = this;
      Axios.get(this.baseURL + '/api/gasstation/level').then(response => {
          self.levelMOGASRead = response.data.mogas;
          self.levelAVGASRead = response.data.avgas;
      });
  }

  private setFuelLevels () {
      this.$v.$touch();
      if (
          this.$v.levelMOGAS.$invalid === false &&
      this.$v.levelAVGAS.$invalid === false
      ) {
          this.$store.dispatch('tankstelleUpdater/setUpdate', {
              type: 'setFuelLevels',
              task: {
                  mogas: this.levelMOGAS,
                  avgas: this.levelAVGAS
              }
          });

          this.fuelLevel = false;
          this.levelMOGAS = null;
          this.levelAVGAS = null;
      }
  }

  private setTransponder () {
      this.$v.$touch();
      if (
          this.$v.transponderCode.$invalid === false &&
          this.$v.transponderRegistration.$invalid === false &&
          this.$v.transponderOwner.$invalid === false &&
          this.$v.transponderDescription.$invalid === false
      ) {
          this.$store.dispatch('tankstelleUpdater/setUpdate', {
              type: 'setTransponder',
              task: {
                  transponderCode: this.transponderCode.toUpperCase(),
                  transponderRegistration: this.transponderRegistration.toUpperCase(),
                  transponderOwner: this.transponderOwner,
                  transponderAccount: this.transponderAccount,
                  transponderDescription: this.transponderDescription,
                  transponderMOGAS: this.transponderMOGAS,
                  transponderAVGAS: this.transponderAVGAS
              }
          });

          this.newTransponder = false;
          this.transponderCode = '';
          this.transponderRegistration = '';
          this.transponderOwner = '';
          this.transponderAccount = '';
          this.transponderDescription = '';
          this.transponderMOGAS = false;
          this.transponderAVGAS = false;
      }
  }

  private setEditTransponder () {
      this.$v.$touch();
      if (this.selectedAirplane !== 'Alle Flugzeuge' && (this.transponderMOGAS || this.transponderAVGAS)) {
          this.$store.dispatch('tankstelleUpdater/setUpdate', {
              type: 'editTransponder',
              task: {
                  transponderRegistration: this.selectedAirplane.toUpperCase(),
                  transponderMOGAS: this.transponderMOGAS,
                  transponderAVGAS: this.transponderAVGAS
              }
          });

          this.editTransponder = false;
          this.selectedAirplane = 'Alle Flugzeuge';
          this.transponderMOGAS = false;
          this.transponderAVGAS = false;
      }
  }

  private formatDate (timestamp: number): string {
      return moment.unix(timestamp).format('DD.MM.YYYY');
  }

  get codeErrors () {
      const errors: string[] = [];
      if (!this.$v.transponderCode.$dirty) return errors;
      !this.$v.transponderCode.minLength &&
      errors.push('Transponder Code muss 20 Zeichen haben.');
      !this.$v.transponderCode.required &&
      errors.push('Transponder Code wird benötigt.');
      return errors;
  }

  get registrationErrors () {
      const errors: string[] = [];
      if (!this.$v.transponderRegistration.$dirty) return errors;
      !this.$v.transponderRegistration.required &&
      errors.push('Registrierung wird benötigt.');
      return errors;
  }

  get ownerErrors () {
      const errors: string[] = [];
      if (!this.$v.transponderOwner.$dirty) return errors;
      !this.$v.transponderOwner.required &&
      errors.push('Eigentümer wird benötigt.');
      return errors;
  }

  get descriptionErrors () {
      const errors: string[] = [];
      if (!this.$v.transponderDescription.$dirty) return errors;
      !this.$v.transponderDescription.required &&
      errors.push('Beschreibung wird benötigt.');
      return errors;
  }

  // TODO mind. MOGAS oder AVGAS

  get levelAVGASErrors () {
      const errors: string[] = [];
      if (!this.$v.levelAVGAS.$dirty) return errors;
      !this.$v.levelAVGAS.required && errors.push('AVGAS Liter wird benötigt.');
      return errors;
  }

  get levelMOGASErrors () {
      const errors: string[] = [];
      if (!this.$v.levelMOGAS.$dirty) return errors;
      !this.$v.levelMOGAS.required && errors.push('MOGAS Liter wird benötigt.');
      return errors;
  }

  get priceAVGASErrors () {
      const errors: string[] = [];
      if (!this.$v.priceAVGAS.$dirty) return errors;
      !this.$v.priceAVGAS.required && errors.push('AVGAS Preis wird benötigt.');
      return errors;
  }

  get priceMOGASErrors () {
      const errors: string[] = [];
      if (!this.$v.priceMOGAS.$dirty) return errors;
      !this.$v.priceMOGAS.required && errors.push('MOGAS Preis wird benötigt.');
      return errors;
  }

  private created () {
      this.loadData();
      this.loadPlanes();
      this.getFuelLevels();

      this.canSeeRechnungen = this.$store.getters['user/hasRole'](
          'app_rechnungen'
      );
      this.isAdmin = this.$store.getters['user/hasRole']('app_tankstelle');
  }
}
