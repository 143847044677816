
import Vue from 'vue';
import Component from 'vue-class-component';
import Card from '@/components/card/Card.vue';
import { mapGetters } from 'vuex';
import * as loadedHaulFlight from '@/data/haulflight.ts';

@Component({
    components: {
        Card
    },
    computed: {
        ...mapGetters({
            competition: 'competition/competition',
            hasRole: 'user/hasRole'
        })
    }
})
export default class HaulFlightPage extends Vue {
  private haulFlight: any = loadedHaulFlight.default;

  private update: boolean = false;

  private items: any = [
      { value: 'club', text: 'Club-/Standardklasse' },
      { value: 'dosi', text: '18m/Leistungs-/Offeneklasse' },
      { value: 'junioren', text: 'Junioren' },
      { value: 'landesliga', text: 'Landesliga' }
  ];

  private selectedItem: string = 'club';
  private json: string = '';

  private updateData (): any {
      this.$store.dispatch('competition/createCompetition', {
          title: this.selectedItem,
          data: this.json
      });
      this.json = '';
      this.update = false;
  }
}
