import * as firebase from 'firebase';

const state: any = {
    news: []
};

const mutations = {
    news: (state: any, payload: any) => {
        state.news = payload;
    }
};

const actions = {
    loadData: ({ commit }: any) => {
        firebase
            .firestore()
            .collection('news')
            .orderBy('date', 'desc')
            .limit(10)
            .onSnapshot((querySnapshot: any) => {
                const news: any = [];
                querySnapshot.forEach((doc: any) => {
                    news.push({ id: doc.id, card: doc.data() });
                });

                commit('news', news);
            });
    },

    createNews: ({ commit }: any, payload: any) => {
        const news = {
            title: payload.title,
            content: payload.content,
            type: 'html',
            date: new Date(),
            user: payload.user
        };

        let id: any;

        firebase
            .firestore()
            .collection('news')
            .add(news)
            .then(docRef => {
                id = docRef.id;
                return id;
            })
            .then(id => {
                return firebase
                    .storage()
                    .ref('news/' + id)
                    .put(payload.image);
            })
            .then(fileData => {
                fileData.task.snapshot.ref.getDownloadURL().then(downloadURL => {
                    return firebase
                        .firestore()
                        .collection('news')
                        .doc(id)
                        .update({ image: downloadURL });
                });
            })
            .catch(error => {
                console.log(error);
            });
    },
    removeNews: ({ commit }: any, payload: any) => {
        firebase
            .firestore()
            .collection('news')
            .doc(payload)
            .delete();
    }
};

const getters = {
    news: (state: any) => {
        return state.news;
    }
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};
