
import Axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import Card from '@/components/card/Card.vue';
import Downloads from '@/components/downloads/Downloads.vue';

@Component({
    components: {
        Card,
        Downloads
    }
})
export default class WeatherPage extends Vue {
  private baseURL: string = 'https://402d345c48.mpx-easystar.de';
  // private baseURL: string = 'http://lscappbackend.development';

  private weather: string = '';
  private spinner: boolean = false;

  private downloads: any = [
      {
          name: 'DWD - Wetterschlüssel',
          link:
        'https://firebasestorage.googleapis.com/v0/b/lsc-quick-reference.appspot.com/o/weather%2Fmetar_taf.pdf?alt=media&token=8ed7d232-158a-42d8-b842-e694fefb69da'
      }
  ];

  private created () {
      const self = this;

      self.spinner = true;

      this.$store.dispatch('user/autoLogin').then(() => {
          Axios.get(this.baseURL + '/api/weather')
              .then(response => {
                  self.weather = response.data;
                  self.spinner = false;
              })
              .catch(error => {
                  console.log(error);
                  self.spinner = false;
              });
      });
  }
}
