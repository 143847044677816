import { render, staticRenderFns } from "./Downloads.vue?vue&type=template&id=0e546cc8&scoped=true"
import script from "./Downloads.vue?vue&type=script&lang=ts"
export * from "./Downloads.vue?vue&type=script&lang=ts"
import style0 from "./Downloads.vue?vue&type=style&index=0&id=0e546cc8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e546cc8",
  null
  
)

export default component.exports