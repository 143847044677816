import Vue from 'vue';
import Router from 'vue-router';
import NewsPage from '@/components/pages/NewsPage.vue';
import CoronaPage from '@/components/pages/CoronaPage.vue';
import PlanesPage from '@/components/pages/PlanesPage.vue';
import ReservationPage from '@/components/pages/ReservationPage.vue';
import HaulFlightPage from '@/components/pages/HaulFlightPage.vue';
import WeatherPage from '@/components/pages/WeatherPage.vue';
import EmergencyPage from '@/components/pages/EmergencyPage.vue';
import GasstationPage from '@/components/pages/GasstationPage.vue';
import KioskPage from '@/components/pages/KioskPage.vue';
import FluglehrerMotorflugPage from '@/components/pages/FluglehrerMotorflugPage.vue';
import FluglehrerSegelflugPage from '@/components/pages/FluglehrerSegelflugPage.vue';
import LoginPage from '@/components/pages/LoginPage.vue';
import StatisticsPage from '@/components/pages/StatisticsPage.vue';
import ImprintPage from '@/components/pages/ImprintPage.vue';
import VoucherPage from '@/components/pages/VoucherPage.vue';
import AuthGuard from './auth-guard';

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/infodeskDesktop',
            name: 'InfoDeskDesktop',
            component: ReservationPage,
            beforeEnter: AuthGuard
        },
        {
            path: '*',
            redirect: '/'
        },
        {
            path: '/emergency',
            name: 'Notfall',
            component: EmergencyPage
        },
        {
            path: '/login',
            name: 'Anmelden',
            component: LoginPage
        },
        {
            path: '/',
            name: 'News',
            component: NewsPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/corona',
            name: 'Kontaktverfolgung',
            component: CoronaPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/planes',
            name: 'Flugzeuge',
            component: PlanesPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/infodesk',
            name: 'InfoDesk',
            component: ReservationPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/haulflight',
            name: 'Streckenflug',
            component: HaulFlightPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/statistics',
            name: 'Statistik',
            component: StatisticsPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/weather',
            name: 'Wetter',
            component: WeatherPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/vouchers',
            name: 'Gutscheine',
            component: VoucherPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/kiosk',
            name: 'Kioskguthaben',
            component: KioskPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/gasstation',
            name: 'Tankstelle',
            component: GasstationPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/fluglehrer_motorflug',
            name: 'Fluglehrer Motorflug',
            component: FluglehrerMotorflugPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/fluglehrer_segelflug',
            name: 'Fluglehrer Segelflug',
            component: FluglehrerSegelflugPage,
            beforeEnter: AuthGuard
        },
        {
            path: '/imprint',
            name: 'Impressum',
            component: ImprintPage
        }
    ],
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
});
