
import Vue from 'vue';
import Component from 'vue-class-component';
import store from '@/store';
import Card from '@/components/card/Card.vue';
import { mapGetters } from 'vuex';
import ImageUploader from 'vue-image-upload-resize';

@Component({
    components: {
        Card,
        ImageUploader
    },
    computed: {
        ...mapGetters({
            news: 'news/news',
            hasRole: 'user/hasRole'
        })
    }
})
export default class NewsPage extends Vue {
  public $refs: Vue['$refs'] & {
    fileInput: HTMLFormElement;
  };

  private showAdd: boolean = false;

  private title: string = '';
  private content: string = '';
  private imageURL: any = '';
  private image: any;

  private onPickFile () {
      const input: any = document.getElementById('fileInput');
      input.click();
  }

  private onFilePicked (file: any) {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
          this.imageURL = fileReader.result;
      });

      fileReader.readAsDataURL(file);
      this.image = file;
  }

  private createNews () {
      this.$store
          .dispatch('news/createNews', {
              title: this.title,
              content: this.content,
              imageURL: this.imageURL,
              image: this.image,
              user: store.getters['user/username']
          })
          .then(() => {
              this.showAdd = false;
              this.title = '';
              this.content = '';
              this.imageURL = '';
              this.image = null;
          });
  }
}
