
import qs from 'qs';
import Axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import Card from '@/components/card/Card.vue';

@Component({
    components: {
        Card
    }
})
export default class LoginPage extends Vue {
  private baseURL: string = 'https://402d345c48.mpx-easystar.de';
  // private baseURL: string = 'http://lscappbackend.development';

  private passwordHidden: boolean = true;

  private email: string = '';
  private password: string = '';
  private secondFactorSwitch: boolean = false;
  private secondFactor: string = '';

  private errorMessage: string = '';

  private spinner: boolean = false;

  private login () {
      if (this.email !== '' && this.password !== '') {
          const self = this;

          self.spinner = true;

          Axios.post(
              this.baseURL + '/authenticate',
              qs.stringify({ username: this.email, password: this.password, secondFactor: this.secondFactor })
          )
              .then(response => {
                  self.$store.dispatch('user/login', {
                      data: response.data,
                      redirectRoute: this.$route.query.redirect
                  });
              })
              .catch(error => {
                  self.errorMessage = error.response.data;
                  self.spinner = false;
              });
      }
  }
}
