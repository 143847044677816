const haulflight = {
    cards: [
        {
            title: 'Frequenzen',
            type: 'table',
            classes: 'bgBlue',
            data: [
                { key: 'Streckenflugkommunikation', description: '', value: '122.540' },
                { key: 'Streckenflugkommunikation', description: '', value: '122.555' },
                { key: 'Streckenflugkommunikation', description: '', value: '130.430' },
                { key: 'Segelflug-ATIS NRW', description: '', value: '126.150' }
            ]
        }
    ]
};

export default haulflight;
