const fi = [];

fi.push({
    name: 'none2lapl',
    from: 'none',
    to: 'LAPL-A',
    revision: 1,
    training_program: [
        {
            key: 'none2lapl_01',
            index: '01',
            name: 'Vertrautmachen mit dem Flugzeug',
            training_program_detail: [
                {
                    key: 'none2lapl_01_01',
                    name: 'Eigenschaften'
                },
                {
                    key: 'none2lapl_01_01',
                    name: 'Gestaltung des Cockpits'
                },
                {
                    key: 'none2lapl_01_01',
                    name: 'Klarlisten, Handgriffe, Steuerbedienungsorgane'
                },
                {
                    key: 'none2lapl_01_01',
                    name: 'Klarlisten, Handgriffe, Steuerbedienungsorgane'
                }
            ]
        }
    ]
});

export default fi;
